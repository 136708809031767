import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import BackgroundImg from "../../assets/pictures/defaultBranch.png";
import SambazaHub from "../../assets/pictures/SambazaHub.webp";
import Wezesha from "../../assets/pictures/WezeshaHub.webp";

import { useWarehouses } from "../../hooks/useProducts";

// Set the app element for accessibility (required by react-modal)
Modal.setAppElement("#root");

const getBranchImage = branch => {
  switch (true) {
    case branch?.toLowerCase().includes("wezesha"):
      return Wezesha;
    default:
      return SambazaHub;
  }
};

// localStorage keys
export const SELECTED_BRANCH_KEY = "selectedMdcBranch";

const StyledModal = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 500px;
  padding: 0;
  outline: none;

  @media (max-width: 480px) {
    width: 95%;
    top: 45%;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #eaeaea;

  @media (max-width: 480px) {
    padding: 12px 16px;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  p {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #777;
  padding: 0;
  &:hover {
    color: #333;
  }
`;

const BranchesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px;
  gap: 16px;

  @media (max-width: 480px) {
    padding: 16px 12px;
    gap: 12px;
  }

  @media (max-width: 360px) {
    justify-content: center;
  }
`;

const BranchCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 130px;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 480px) {
    width: 110px;
  }

  @media (max-width: 360px) {
    width: 90%;
    max-width: 140px;
    margin-bottom: 8px;
  }
`;

const BranchImage = styled.img`
  width: 100%;
  height: 90px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 8px;
  border: ${props =>
    props.selected ? "2px solid rgba(73, 67, 149, 0.9)" : "1px solid #eaeaea"};

  @media (max-width: 480px) {
    height: 80px;
  }
`;

const BranchName = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: ${props => (props.selected ? "700" : "500")};
  color: ${props => (props.selected ? "rgba(73, 67, 149, 0.9)" : "#333")};
  word-wrap: break-word;

  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const SelectedBranchInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 8px 12px;
  background-color: #f9f9f9;
  border-radius: 6px;
  border-left: 3px solid #ff5a5f;
`;

export const BranchSelectionModal = ({
  isOpen,
  onRequestClose,
  onSelectBranch
}) => {
  const { data: branches } = useWarehouses();

  // Initialize selected branch from localStorage if available
  const [selectedBranch, setSelectedBranch] = useState(() => {
    try {
      const savedBranch = localStorage.getItem(SELECTED_BRANCH_KEY);
      if (savedBranch) {
        const parsed = JSON.parse(savedBranch);
        // Verify the branch still exists in our list
        const found = branches?.find(branch => branch.id === parsed.id);
        return found || null;
      }
      return null;
    } catch (error) {
      console.error("Error reading from localStorage:", error);
      return null;
    }
  });

  // Effect to update the parent component with the selected branch on mount
  useEffect(() => {
    if (selectedBranch) {
      onSelectBranch(selectedBranch);
    }
  }, []);

  const handleBranchSelect = branch => {
    setSelectedBranch(branch);

    // Save to localStorage
    try {
      localStorage.setItem(SELECTED_BRANCH_KEY, JSON.stringify(branch));
    } catch (error) {
      console.error("Error saving to localStorage:", error);
    }

    // Notify parent component and close modal
    setTimeout(() => {
      onSelectBranch(branch);
      onRequestClose();
    }, 300);
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Select a Branch"
      overlayClassName="modal-overlay"
      overlayElement={(props, contentElement) => (
        <ModalOverlay {...props}>{contentElement}</ModalOverlay>
      )}
    >
      <ModalHeader>
        <Title>
          <p>Our Branches</p>
          <small>Please select your nearest branch</small>
        </Title>
        <CloseButton onClick={onRequestClose} aria-label="Close">
          ×
        </CloseButton>
      </ModalHeader>

      <BranchesContainer>
        {branches?.map(branch => (
          <BranchCard
            key={branch.id}
            onClick={() => handleBranchSelect(branch)}
          >
            <BranchImage
              src={getBranchImage(branch.name)}
              alt={branch.name}
              selected={selectedBranch?.id === branch.id}
            />
            <BranchName selected={selectedBranch?.id === branch.id}>
              {branch.name}
            </BranchName>
          </BranchCard>
        ))}
      </BranchesContainer>
    </StyledModal>
  );
};
